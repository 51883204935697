<template lang="pug">
.common-create-stepper-edit-dialog
  v-dialog(
    max-width="650"
    persistent
    v-model="isOpen"
  )
    FeathersVuexFormWrapper(
      :item="item"
      :eager="false"
    )
      template( v-slot="{ save, reset, clone }" )
        v-form(
          ref="createEditForm"
          v-model="valid"
          @submit.prevent="saveForm({ save, reset, clone })"
        )
          v-card
            v-card-text.pa-0.ma-0
              slot(
                name="stepper"
                v-bind:save="save"
                v-bind:reset="reset"
                v-bind:clone="clone"
                v-bind:rules="rules"
                v-bind:valid="valid"
              )

            v-card-actions( right ).bg-gray-100
              slot(
                name="action"
                v-bind:clone="clone"
                v-bind:valid="valid"
              )
</template>

<script>
import formValidations from '../../helpers/formValidations'

export default {
  name: 'CreateStepperEditDialog',

  props: {
    isOpen: {
      required: true,
      type: Boolean,
      default: false
    },

    item: {
      type: Object,
      required: true
    },

    title: {
      type: String,
      required: false,
      default: 'Add'
    },

    saveAction: {
      type: String,
      required: false,
      default: 'Save'
    }
  },

  setup(props, { emit }) {
    const { valid, rules } = formValidations()
    const saveForm = ({ save, reset, clone }) =>
      emit('submit-form', { save, reset, clone })

    return {
      valid,
      rules,
      saveForm
    }
  }
}
</script>

<style scoped>
::v-deep .v-stepper__wrapper { padding: 5px }
</style>